import DcbService from "@/services/dcbService";

export async function tokenLogin() {
    const response = await DcbService.getUserViaToken(localStorage.getItem('sessionId'));
    if (response.data.result) {
        if (isTokenValid(response)) return response;
    }
    if (response.data.ExpiredSession) {
        return response
    } else return response
}

//TODO rework this to user token for brand
// export async function tokenLogin() {
//     const response = await DcbService.getUserViaToken(localStorage.getItem('token'));
//     if (response.data.result) {
//         if (isTokenValid(response)) return response;
//     }
// }

function isTokenValid(response) {
    if (response.data.InvalidSession || response.data.ExpiredSession) return false
    return response.data.result !== 'fail';
}
