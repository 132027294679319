import DcbService from "@/services/dcbService";
import {tokenLogin} from "@/functions/tokenLogin";
import store from "@/store";

export async function userAuthenticate(to, from, next) {
    return await userAuthMsisdn(to, from, next);
}

async function userAuthMsisdn(to, from, next) {
    if (from.fullPath === "/" && !localStorage.getItem('sessionId') && !to.fullPath.includes('e-msisdn')) return next();
    if (store.state.user) return next();
    if (to.name === 'TermsConditions' || to.name === 'RequestOTP') return next();

    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMsisdn = urlParams.get('e-msisdn');
    const campaignId = urlParams.get('campaignId');
    const sessionId = localStorage.getItem('sessionId');

    if (campaignId) setCampaignId(campaignId)
    if (encryptedMsisdn) return await encryptedMsisdnAuth(encryptedMsisdn, next);
    if (sessionId) return await sessionLoginAuth(sessionId, next);

    store.commit('setIsLoading', false);
}

function setCampaignId(campaignId) {
    store.commit('setCampaignId', campaignId);
}

async function sessionLoginAuth(sessionId, next) {
    let tokenLoginResponse = await tokenLogin(sessionId);
    if (tokenLoginResponse.data.status === "Active") {
        await setUserData(tokenLoginResponse.data)
        return next({name: 'LandingPage'});
    } else {
        localStorage.removeItem('sessionId');
        return next({name: 'RequestOTP'});
    }
}

async function encryptedMsisdnAuth(encryptedMsisdn, next) {
    const decryptMsisdnResponse = await DcbService.decryptMsisdn(encryptedMsisdn);
    const userDataResponse = await getUser(decryptMsisdnResponse.data.rawMsisdn);
    await setUserData(userDataResponse)
    return redirectBasedOnUserStatus(store.state.user, next);
}

async function setUserData(userDataResponse) {
    await DcbService.trackLogin(userDataResponse.msisdn);
    store.commit('setUser', userDataResponse);
    store.commit('setMsisdn', userDataResponse.msisdn);
    localStorage.setItem('token', userDataResponse.token);
}

function redirectBasedOnUserStatus(userStatus, next) {
    store.commit('setIsLoading', false);
    if (userStatus.status === "Active") return next({name: 'LandingPage'});
    else return next({name: 'DoiOne'});
}

async function getUser(msisdn) {
    const {data: userResponse} = await DcbService.getUserViaProductId(msisdn, 3);
    if (userResponse.status === "Active") {
        return userResponse;
    } else {
        const {data: userResponse} = await DcbService.getUserViaProductId(msisdn, 24);
        return userResponse
    }
}
