
export const productionConfig = {
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    quizIncRedirectUrl: `${process.env.VUE_APP_QUIZ_INC_REDIRECT_URL}`,
    dailyFiveRedirectUrl: `${process.env.VUE_APP_DAILY_FIVE_REDIRECT_URL}`,
    quizIncTermsConditionsHub: `${process.env.VUE_APP_QUIZ_INC_TERMS_CONDITIONS_URL}`,
    campaignHubService: `${process.env.VUE_APP_CAMPAIGN_HUB_SERVICE}`,
}

export const stagingConfig = {
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE_STAGING}`,
    dcbService: `${process.env.VUE_APP_DCB_SERVICE}`,
    utilsService: `${process.env.VUE_APP_UTILS_SERVICE}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADERS}`,
    quizIncRedirectUrl: `${process.env.VUE_APP_QUIZ_INC_REDIRECT_URL}`,
    dailyFiveRedirectUrl: `${process.env.VUE_APP_DAILY_FIVE_REDIRECT_URL}`,
    quizIncTermsConditionsHub: `${process.env.VUE_APP_QUIZ_INC_TERMS_CONDITIONS_URL}`,
    campaignHubService: `${process.env.VUE_APP_CAMPAIGN_HUB_SERVICE}`,
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    let base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}
