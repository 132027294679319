import {createStore} from 'vuex'

export default createStore({
    state: {
        isLoading: false,
        userScore: false,
        user: undefined,
        encryptedMsisdn: undefined,
        token: undefined,
        msisdn: undefined,
        userQuizFinishingTime: undefined,
        brandConfig: undefined,
        burgerMenuIconVisible: false,
        campaignId: null,
        googleClickId: undefined,
        leaderboardSmsReminderPopup: false,
        coolDownTimerPopup: false,
        rewardPopUp: false,
        evinaToken: undefined,
        evinaScript: undefined,
        subscriptionPackage: undefined,
        serviceId: undefined,
        dlsQuizFinishPopup: false,
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setUserScore(state, userScore) {
            state.userScore = userScore
        },
        setUser(state, user) {
            state.user = user
        },
        setMsisdn(state, msisdn) {
            state.msisdn = msisdn
        },
        setEncryptedMsisdn(state, encryptedMsisdn) {
            state.encryptedMsisdn = encryptedMsisdn
        },
        setToken(state, token) {
            state.token = token
        },
        setUserQuizFinishingTime(state, userQuizFinishingTime) {
            state.userQuizFinishingTime = userQuizFinishingTime
        },
        setBrandConfig(state, brandConfig) {
            state.brandConfig = brandConfig
        },
        setBurgerMenuIconVisible(state, burgerMenuIconVisible) {
            state.burgerMenuIconVisible = burgerMenuIconVisible
        },
        setCampaignId(state, campaignId) {
            state.campaignId = campaignId
        },
        setGoogleClickId(state, googleClickId) {
            state.googleClickId = googleClickId
        },
        setLeaderboardSmsReminderPopup(state, leaderboardSmsReminderPopup) {
            state.leaderboardSmsReminderPopup = leaderboardSmsReminderPopup
        },
        setEvinaToken(state, evinaToken) {
            state.evinaToken = evinaToken
        },
        setEvinaScript(state, evinaScript) {
            state.evinaScript = evinaScript
        },
        setCoolDownTimerPopup(state, coolDownTimerPopup) {
            state.coolDownTimerPopup = coolDownTimerPopup
        },
        setRewardPopup(state, rewardPopUp) {
            state.rewardPopUp = rewardPopUp
        },
        setSubscriptionPackage(state, subscriptionPackage) {
            state.subscriptionPackage = subscriptionPackage
        },
        setServiceId(state, serviceId) {
            state.serviceId = serviceId
        },
        setDlsQuizFinishPopup(state, dlsQuizFinishPopup) {
            state.dlsQuizFinishPopup = dlsQuizFinishPopup
        },
    },
    actions: {},
    modules: {}
})
